import { ContainerCard } from '@/components/card/ContainerCard';
import { Container } from '@/components/container/Container';
import { Heading } from '@/components/heading/Heading';
import { Input } from '@/components/input/Input';
import { Page } from '@/components/page/Page';
import {
  ReadExternalTrackingInfoDocument,
  ReadExternalTrackingInfoQuery,
  ReadExternalTrackingInfoQueryVariables,
  ReadTrackingInfoDocument,
  ReadTrackingInfoQuery,
  ReadTrackingInfoQueryVariables,
} from '@/generated/graphql';
import { ApolloError, isApolloError, useApolloClient } from '@apollo/client';
import { Button } from '@packfleet/ui';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { IoSearch } from 'react-icons/io5';

import { Routes, route } from '@/utilities/routes';
import {
  ShipmentTypes,
  getShipmentTypeFromTrackingNumber,
} from '@/utilities/shipments';

function Tracking() {
  const router = useRouter();
  const client = useApolloClient();

  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [error, setError] = useState<{ message: string } | undefined>();

  const trackShipment = useCallback(async () => {
    if (!trackingNumber) return;

    const isPackfleetShipment =
      getShipmentTypeFromTrackingNumber(trackingNumber) ===
      ShipmentTypes.INTERNAL;

    try {
      if (isPackfleetShipment) {
        const result = await client.query<
          ReadTrackingInfoQuery,
          ReadTrackingInfoQueryVariables
        >({
          query: ReadTrackingInfoDocument,
          variables: {
            input: {
              trackingNumber: trackingNumber,
              includeDeleted: true,
            },
          },
        });

        if (result.errors?.length) {
          setError({ message: 'Something went wrong. Please try again' });
        } else if (result?.data?.readTrackingInfo?.error) {
          setError(result?.data?.readTrackingInfo?.error);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          router.push(
            route(Routes.trackingDetail, { trackingNumber: trackingNumber }),
          );
        }
      } else {
        const result = await client.query<
          ReadExternalTrackingInfoQuery,
          ReadExternalTrackingInfoQueryVariables
        >({
          query: ReadExternalTrackingInfoDocument,
          variables: {
            input: {
              trackingNumber: trackingNumber,
            },
          },
        });

        if (result.errors?.length) {
          setError({ message: 'Something went wrong. Please try again' });
        } else if (result?.data?.readExternalTrackingInfo?.error) {
          setError(result?.data?.readExternalTrackingInfo?.error);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          router.push(
            route(Routes.externalTrackingDetail, {
              canonicalName:
                result.data.readExternalTrackingInfo?.trackingInfo?.organization
                  .canonicalName,
              carrierCode:
                result.data.readExternalTrackingInfo?.trackingInfo?.shipment
                  .carrierCode,
              trackingNumber: trackingNumber,
            }),
          );
        }
      }
    } catch (e) {
      if (!isApolloError(e)) throw e;
      setError(e as ApolloError);
    }
  }, [trackingNumber]);

  return (
    <Page
      title="Track your Shipment | Packfleet"
      // We should index the top level search tracking page but the actual tracking views should
      // not be indexed as these contain customer details
      robotsIndexEnabled={true}
    >
      <Container>
        <div className="mx-auto mt-8 max-w-6xl overflow-hidden">
          <div className="p-8 pb-36">
            <div
              className="align-center flex justify-center"
              style={{ fontSize: 80 }}
            >
              📦
            </div>
            <Heading
              level={1}
              className="leading-small mb-8 mt-2 text-center text-brandPrimary"
              headingStyle="title"
            >
              track your shipment
            </Heading>
            <ContainerCard className="mx-auto max-w-xl">
              <p className="text-secondary">
                Enter your two word tracking phrase (separated by a hyphen)
                below.
              </p>
              <form
                className="mt-4 flex flex-col gap-4 sm:flex-row"
                onSubmit={async (e) => {
                  e.preventDefault();
                  await trackShipment();
                }}
              >
                <div className="flex-1">
                  <Input
                    className="w-full flex-1"
                    type="text"
                    id="tracking-number"
                    name="tracking-number"
                    autoCapitalize="off"
                    autoFocus
                    onChange={(e) => {
                      setTrackingNumber(e.currentTarget.value);
                    }}
                    placeholder="beautiful-ship"
                    value={trackingNumber}
                  />
                </div>
                <Button
                  type="submit"
                  mode="secondary"
                  color="brand"
                  round
                  icon={IoSearch}
                >
                  Track
                </Button>
              </form>
              {error ? (
                <div className="mt-4 font-bold text-warning">
                  {error.message}
                </div>
              ) : null}
            </ContainerCard>
          </div>
        </div>
      </Container>
    </Page>
  );
}

export default Tracking;
